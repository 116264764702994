@layer components {
  .btn {
    @apply inline-block px-4 py-3 text-sm text-white transition-all bg-green-300 rounded-lg drop-shadow-sm lg:filter-none lg:px-7 lg:py-5 lg:text-lg lg:hover:drop-shadow-sm lg:hover:translate-x-[5px] lg:hover:translate-y-[-5px] will-change-[filter,transform];
  }
  .hover-img {
    @apply text-green-300 transition drop-shadow-sm lg:drop-shadow 2xl:drop-shadow-lg sm:hover:drop-shadow lg:hover:drop-shadow-lg 2xl:hover:drop-shadow-xl sm:hover:translate-x-[5px] sm:hover:translate-y-[-5px] will-change-[filter,transform];
  }

  blockquote::before,
  blockquote::after {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem !important;
  }
  
  @media screen and (min-width: 1024px) {
    blockquote::before,
    blockquote::after {
      font-size: 1.25rem;
      line-height: 1.75rem !important;
    }
  }

  blockquote::before {
    content: "\201C"
  }
  
  blockquote::after {
    content: "\201D"
  }
}
